const ImpersonationAccess = () => import( '../../rootComponents/super/ImpersonationAccess.vue');
const CreateOrEditSchool = () => import( '../../rootComponents/super/CreateOrEditSchool.vue');
const ImpersonateUsers = () => import( '../../rootComponents/super/ImpersonateUsers.vue');
const SchoolPurchases = () => import( '../../rootComponents/super/billing/SchoolPurchases.vue');
const SuperAdmin = () => import( '../../rootComponents/super/SuperAdmin.vue');
const SuperSchools = () => import( '../../rootComponents/super/SuperSchools.vue');
const PublicUploads = () => import( '../../rootComponents/super/PublicUploads.vue');
const TransactionDetails = () => import( '../../rootComponents/super/billing/TransactionDetails.vue');
const Transactions = () => import( '../../rootComponents/super/billing/Transactions.vue');
const ManageAWS = () => import( '../../rootComponents/super/ManageAWS.vue');
const CreateLocalUser = () => import( '../../rootComponents/super/CreateLocalUser.vue');
const EmailSignatures = () => import( '../../rootComponents/super/EmailSignatures.vue');

const superRoutes = [
    {
        name: 'SuperAdmin',
        path: '/configuration/super',
        component: SuperAdmin,
        children: [
            {
                name: 'SuperSchools',
                title: 'Schools',
                path: '/configuration/super/schools',
                component: SuperSchools,
                hideFromNav: false,
                meta: {
                    subHeader: true,
                    roles: ['All'],
                },
            },
            {
                name: 'ImpersonateUsers',
                title: 'Impersonate',
                path: '/configuration/super/impersonate',
                component: ImpersonateUsers,
                hideFromNav: false,
                meta: {
                    roles: ['All'],
                },
            },
            {
                name: 'CreateOrEditSchool',
                title: 'School Details',
                path: '/configuration/super/schools/:externalSchoolId',
                component: CreateOrEditSchool,
                hideFromNav: true,
                meta: {
                    roles: ['All'],
                },
            },
            {
                name: 'PublicUploads',
                title: 'Public Uploads',
                path: '/configuration/super/public-uploads',
                component: PublicUploads,
                hideFromNav: false,
                meta: {
                    subHeader: true,
                    roles: ['All'],
                },
            },
            {
                name: 'SchoolPurchases',
                title: 'Purchases',
                path: '/configuration/super/purchases',
                component: SchoolPurchases,
                hideFromNav: false,
                isBillingPage: true,
                meta: {
                    subHeader: true,
                    roles: ['All'],
                },
            },
            {
                name: 'Transactions',
                title: 'Transactions',
                path: '/configuration/super/transactions',
                component: Transactions,
                hideFromNav: false,
                isBillingPage: true,
                meta: {
                    subHeader: true,
                    roles: ['All'],
                },
            },
            {
                name: 'TransactionDetails',
                title: 'Transaction Details',
                path: '/configuration/super/transactions/:transactionCheckNumber',
                component: TransactionDetails,
                hideFromNav: true,
                isBillingPage: true,
                meta: {
                    subHeader: true,
                    roles: ['All'],
                },
            },
            {
                name: 'ImpersonationAccess',
                title: 'Impersonation Access',
                path: '/configuration/super/bug-reporting',
                component: ImpersonationAccess,
                hideFromNav: false,
                isBillingPage: false,
                meta: {
                    subHeader: false,
                    roles: ['All'],
                },
            },
            {
                name: 'ManageAWS',
                title: 'Manage AWS',
                path: '/configuration/super/manage-aws',
                component: ManageAWS,
                hideFromNav: true,
                meta: {
                    subHeader: true,
                    roles: ['All'],
                },
            },
            {
                name: 'CreateLocalUser',
                title: 'Create Local User',
                path: '/configuration/super/local-user/create',
                component: CreateLocalUser,
                hideFromNav: false,
                meta: {
                    roles: ['All'],
                },
            },
            {
                name: 'Email Signatures',
                title: 'Email Signatures',
                path: '/configuration/super/email-signatures/create',
                component: EmailSignatures,
                hideFromNav: true,
                meta: {
                    roles: ['All'],
                },
            },
        ],
    },
];

export default superRoutes;
