/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import emailSupport from './emailSupport';
import submitSupportTicket from './submitSupportTicket';
import getAWSFormDetails from './getAWSFormDetails';
import getAWSExpiryLink from './getAWSExpiryLink';

export {
    emailSupport,
    submitSupportTicket,
    getAWSFormDetails,
    getAWSExpiryLink,
 };
