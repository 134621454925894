const LandingPage = () => import( '../../rootComponents/LandingPage.vue');
const AboutUs = () => import( '../../rootComponents/AboutUs.vue');
const ContactUs = () => import( '../../rootComponents/ContactUs.vue');
const TechSupport = () => import( '../../rootComponents/TechSupport.vue');
const LandingFileUpload = () => import( '../../rootComponents/LandingFileUpload.vue');
const PrivacyPolicy = () => import( '../../rootComponents/PrivacyPolicy.vue');
const TermsAndConditions = () => import( '../../rootComponents/TermsAndConditions.vue');
const BillOfRights = () => import( '../../rootComponents/BillOfRights.vue');

const landingRoutes = [
    {
        name: 'LandingPage',
        path: '/',
        component: LandingPage,
        meta: {
            roles: ['All'],
            landing: true,
        },
    },
    {
        name: 'ContactUs',
        path: '/contact',
        component: ContactUs,
        meta: {
            roles: ['All'],
            landing: true,
        },
    },
    {
        name: 'AboutUs',
        path: '/about',
        component: AboutUs,
        meta: {
            roles: ['All'],
            landing: true,
        },
    },
    {
        name: 'TechSupport',
        path: '/support',
        component: TechSupport,
        meta: {
            roles: ['All'],
            landing: true,
        },
    },
    {
        name: 'LandingFileUpload',
        path: '/file-upload',
        component: LandingFileUpload,
        meta: {
            roles: ['All'],
            landing: true,
        },
    },
    {
        name: 'PrivacyPolicy',
        path: '/privacy-policy',
        component: PrivacyPolicy,
        meta: {
            roles: ['All'],
            landing: true,
        },
    },
    {
        name: 'TermsAndConditions',
        path: '/terms-and-conditions',
        component: TermsAndConditions,
        meta: {
            roles: ['All'],
            landing: true,
        },
    },
    {
        name: 'BillOfRights',
        path: '/bill-of-rights',
        component: BillOfRights,
        meta: {
            roles: ['All'],
            landing: true,
        },
    },
];

export default landingRoutes;
