import async from 'async';
import * as network from '../network';
import { encrypt, decrypt } from '../../../shared/aes.mjs'
import { hashString, passPhraseToKey } from '../../../shared/aes.mjs'

/**
 * Saves the cipher credentials to sessionStorage for a given state and school password.
 *
 * @param {Object} state - The state object.
 * @param {Function} commit - The commit function from Vuex store.
 * @param {string} schoolPassword - The school password.
 * @param {Function} callback - The callback function to be called after saving the credentials.
 */
function saveCipherCredentials(state, commit, schoolPassword, callback) {
    if (!state.user.school.encryptionHash) return callback(new Error('Encryption not enabled'));

    const { schoolId, schoolTermId } = state.user.school;
    const params = { url: { schoolId, schoolTermId } };

    async.auto({
        schoolTermEncryptionKeyCipherText(next) {
            network.cipher.getSchoolTermEncryptionKey(params, (err, schoolTermEncryptionKeyCipherText) => {
                if (err) return next(err);
                next(err, schoolTermEncryptionKeyCipherText);
            });
        },
        hashEncryptionKey(next) {
            network.cipher.getHashEncryptionKey(params, (err, res) => {
                if (err) return next(err);
                next(null, res.hashEncryptionKey);
            });
        },
        schoolPasswordKey: ['hashEncryptionKey', (results, next) => {
            const { hashEncryptionKey } = results;
            // cant use the school password directly as the key bc it is not the correct length
            passPhraseToKey(schoolPassword, hashEncryptionKey, next);
        }],
        schoolTermEncryptionKey: ['schoolPasswordKey', 'schoolTermEncryptionKeyCipherText', (results, next) => {
            const {schoolPasswordKey, schoolTermEncryptionKeyCipherText} = results;
            const jsonString = Buffer
                .from(schoolTermEncryptionKeyCipherText, 'base64')
                .toString();

            let json;
            try {
                json = JSON.parse(jsonString);
            } catch (e) {
                console.error(e);
            }
            if (!json) return next(new Error('Invalid encryption hash'));
            const { cipherText, iv, tag } = json;
            const schoolTermEncryptionKey = decrypt(schoolPasswordKey, cipherText, iv, tag);
            if (!schoolTermEncryptionKey) return next(new Error('Decryption failed'));
            next(null, schoolTermEncryptionKey);
        }],
        cache: ['schoolTermEncryptionKey', (results, next) => {
            const { hashEncryptionKey } = results;
            const cipherObject = encrypt(hashEncryptionKey, schoolPassword);
            const stek = Buffer.from(JSON.stringify(cipherObject)).toString('base64');
            window.sessionStorage.setItem(`stek_${schoolTermId}`, stek);
            next();
        }],
    }, 5, (err, results) => {
        if (err) return callback(err);
        const { hashEncryptionKey, schoolTermEncryptionKey } = results;

        callback(err, {
            hashEncryptionKey,
            schoolTermEncryptionKey,
        });
    });
}

/**
 * Decrypts the school term encryption key from the school password in
 * session storage cache, and loads into vuex database.schoolTermEncryptionKey.
 *
 * @param {Object} schoolTerm - The school term object containing schoolId and schoolTermId.
 * @param {Function} callback - The callback function to be called with the decrypted school term encryption key.
 */
function decryptSchoolTermEncryptionKey(schoolTerm, callback) {
    const { schoolId, schoolTermId } = schoolTerm;
    const stek = window.sessionStorage.getItem(`stek_${schoolTermId}`);
    if (!stek) return callback(null, null);

    async.auto({
        hashEncryptionKey(next) {
            // gets shared hash encryption key for school term
            getHashEncryptionKey(schoolTerm, next);
        },
        schoolTermEncryptionKeyCipherText(next) {
            // gets the encrypted school term encryption key
            const params = { url: { schoolId, schoolTermId } };
            network.cipher.getSchoolTermEncryptionKey(params, (err, schoolTermEncryptionKeyCipherText) => {
                if (err) return next(err);
                next(err, schoolTermEncryptionKeyCipherText);
            });
        },
        schoolPassword: ['hashEncryptionKey', 'schoolTermEncryptionKeyCipherText', (results, next) => {
            // uses the hash encryption key to decrypt the school password from session storage
            const { hashEncryptionKey } = results;
            if (!hashEncryptionKey) return next();
            let cipherObject;
            try {
                cipherObject = JSON.parse(Buffer.from(stek, 'base64').toString());
            } catch (e) {
                return next(e);
            }
            if (!cipherObject) return next();
            const { cipherText, iv, tag } = cipherObject;
            const schoolPassword = decrypt(hashEncryptionKey, cipherText, iv, tag);
            if (!schoolPassword) return next();
            next(null, schoolPassword);
        }],
        schoolPasswordKey: ['schoolPassword', (results, next) => {
            // use key derivation function to derive the school password key
            const { hashEncryptionKey, schoolPassword, schoolTermEncryptionKeyCipherText } = results;
            if (!schoolTermEncryptionKeyCipherText || !hashEncryptionKey) return next();
            passPhraseToKey(schoolPassword, hashEncryptionKey, next);
        }],
        schoolTermEncryptionKey: ['schoolPasswordKey', (results, next) => {
            // decrypts the school term encryption key using the school password
            const { hashEncryptionKey, schoolPasswordKey, schoolTermEncryptionKeyCipherText } = results;
            if (!schoolTermEncryptionKeyCipherText || !hashEncryptionKey) return next();
            const jsonString = Buffer
                .from(schoolTermEncryptionKeyCipherText, 'base64')
                .toString();

            let json;
            try {
                json = JSON.parse(jsonString);
            } catch (e) {
                console.error(e);
            }
            if (!json) return next(new Error('Invalid encryption hash'));
            const { cipherText, iv, tag } = json;
            const schoolTermEncryptionKey = decrypt(schoolPasswordKey, cipherText, iv, tag);
            if (!schoolTermEncryptionKey) return next(new Error('Decryption failed'));
            next(null, schoolTermEncryptionKey);
        }],
    }, 5, (err, results) => {
        if (err) return callback(err);
        const { schoolTermEncryptionKey } = results;

        callback(err, schoolTermEncryptionKey || null);
    });
}

/**
 * Retrieves the hash encryption key for a given school term.
 * @param {Object} schoolTerm - The school term object containing the schoolId and schoolTermId.
 * @param {function} callback - The callback function to be called with the hash encryption key.
 */
function getHashEncryptionKey(schoolTerm, callback) {
    const { schoolId, schoolTermId } = schoolTerm;
    network.cipher.getHashEncryptionKey({ url: { schoolId, schoolTermId } }, (err, res) => {
        if (err) return callback(err);
        callback(null, res.hashEncryptionKey);
    });
}

export {
    decryptSchoolTermEncryptionKey,
    saveCipherCredentials,
    getHashEncryptionKey,
    hashString,
};
